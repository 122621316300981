<template>
    <v-card>
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                Quality Process
            </h2>
        </v-card-title>
        <v-divider class="mx-6" />
        <v-card-text>
            <v-form v-model="valid">
                <v-data-table
                    :headers="headers"
                    :items="filteredItems"
                    class="elevation-0 mt-7"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="mt-0 mx-0 pt-4 pb-0 pb-4"
                            style="background-color: #eeeeee"
                        >
                            <v-col cols="6" class="d-flex align-center">
                                <h2 class="my-n3 d-flex align-center">
                                    ITEMS
                                </h2></v-col
                            >
                        </v-row>
                    </template>
                    <template v-slot:[`header.partNumber`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="partNumberToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.index`]="{ index }">
                        <p class="my-0">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.code`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">{{ item.code }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div>
                            <p class="my-0">{{ item.description }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.pending`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">
                                {{ item.pending }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.reprocessQty`]="{ item }">
                        <div class="mb-5">
                            <v-text-field
                                v-model="item.reprocessQty"
                                hide-details
                                type="number"
                                :rules="[() => maxValue(item)]"
                            />
                        </div>
                    </template>
                    <template v-slot:[`item.releaseQty`]="{ item }">
                        <div class="mb-5">
                            <v-text-field
                                v-model="item.releaseQty"
                                hide-details
                                type="number"
                                :rules="[() => maxValue(item)]"
                            />
                        </div>
                    </template>
                    <template v-slot:[`item.rejectQty`]="{ item }">
                        <div class="mb-5">
                            <v-text-field
                                v-model="item.rejectQty"
                                hide-details
                                type="number"
                                :rules="[() => maxValue(item)]"
                            />
                        </div>
                    </template>
                    <template v-slot:[`item.NCcode`]="{ item }">
                        <div class="mb-5">
                            <v-text-field
                                v-if="item.rejectQty"
                                v-model="item.NCcode"
                                hide-details
                                :rules="[rules.required]"
                            />
                        </div>
                    </template>
                </v-data-table>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                :loading="loading"
                color="primary"
                rounded
                class="mb-3 mr-3"
                :disabled="disableButton() || !valid"
                @click="openConfirmDialog"
            >
                NEXT
            </v-btn>
        </v-card-actions>
        <!-- CONFIRM DIALOG -->
        <v-dialog
            :retain-focus="false"
            v-model="confirmDialog"
            persistent
            max-width="700px"
        >
            <v-card>
                <v-card-title>
                    <v-btn
                        small
                        icon
                        @click="closeConfirmDialog"
                        color="primary"
                        class="ml-n3 mr-2"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h2 class="font-weight-regular">
                        Confirm Quality Process
                    </h2>
                </v-card-title>
                <v-divider class="mx-6" />
                <v-card-text>
                    <v-form v-model="valid">
                        <v-data-table
                            :headers="confirmHeaders"
                            :items="
                                items.filter(
                                    i =>
                                        i.releaseQty ||
                                        i.reprocessQty ||
                                        i.rejectQty
                                )
                            "
                            class="elevation-0 mt-7"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                            :style="`max-height: ${height}px; overflow-y:auto;`"
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                                    style="background-color: #eeeeee"
                                >
                                    <v-col
                                        cols="12"
                                        class="d-flex align-center"
                                    >
                                        <h2 class="my-n3 d-flex align-center">
                                            ITEMS
                                        </h2></v-col
                                    >
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.code`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.code }}</p>
                                </div>
                            </template>
                            <template v-slot:[`item.release`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.releaseQty }}</p>
                                </div>
                            </template>
                            <template v-slot:[`item.reprocess`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">
                                        {{ item.reprocessQty }}
                                    </p>
                                </div>
                            </template>
                            <template v-slot:[`item.reject`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.rejectQty }}</p>
                                </div>
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pt-0 pb-5">
                    <v-row class="ma-2">
                        <v-spacer />
                        <v-col cols="3" class="d-flex justify-end pa-0 ">
                            <v-btn
                                color="primary"
                                rounded
                                :disabled="!valid"
                                :loading="loading"
                                @click="registerQuality"
                            >
                                SAVE
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import _ from 'lodash'
import API from '@/services/api'

export default {
    name: 'MultipleQualityProcess',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        valid: false,
        loading: false,
        headers: [
            {
                text: 'ID',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '50',
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'PENDING',
                value: 'pendingQty',
                align: 'center',
                sortable: false,
                width: '100',
            },
            {
                text: 'RELEASE',
                value: 'releaseQty',
                align: 'center',
                sortable: false,
                width: '100',
            },
            {
                text: 'REPROCESS',
                value: 'reprocessQty',
                align: 'center',
                sortable: false,
                width: '100',
            },
            {
                text: 'REJECT',
                value: 'rejectQty',
                align: 'center',
                sortable: false,
                width: '100',
            },
            {
                text: 'NC CODE',
                value: 'NCcode',
                align: 'center',
                sortable: false,
                width: '170',
            },
        ],
        confirmHeaders: [
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RELEASE',
                value: 'releaseQty',
                align: 'center',
                sortable: false,
                width: '100',
            },
            {
                text: 'REPROCESS',
                value: 'reprocessQty',
                align: 'center',
                sortable: false,
                width: '100',
            },
            {
                text: 'REJECT',
                value: 'rejectQty',
                align: 'center',
                sortable: false,
                width: '100',
            },
            {
                text: 'NC CODE',
                value: 'NCcode',
                align: 'center',
                sortable: false,
                width: '170',
            },
        ],
        rules: {
            required: v => !!v || 'The value is required',
        },
        height: 0,
        partNumberToFilter: undefined,
        items: [],
        confirmDialog: false,
    }),
    computed: {
        filteredItems() {
            //filter available
            let conditions = []
            conditions.push(this.filterAvailable)
            if (this.partNumberToFilter) {
                conditions.push(this.filterForPartNumber)
            }
            if (conditions.length > 0) {
                return this.items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }
            return this.items
        },
    },
    mounted() {
        this.items = []
        this.workOrder.items.forEach(item => {
            const copy = _.cloneDeep(item)
            this.calculateValues(copy)
            copy.releaseQty = 0
            copy.reprocessQty = 0
            copy.rejectQty = 0
            this.items.push(copy)
        })
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        calculateValues(item) {
            try {
                item.pendingQty = 0
                const lastProductionProcess =
                    item.processes[item.processes.length - 1]
                if (item.production) {
                    const production = item.production.filter(
                        entry => entry.process.id == lastProductionProcess
                    )
                    if (production.length > 0) {
                        item.pendingQty += production.reduce(
                            (accumulator, entry) => accumulator + entry.qty,
                            0
                        )
                    }
                }
                if (item.quality) {
                    const rejected = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.rejectQty || 0),
                        0
                    )
                    const released = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.releaseQty || 0),
                        0
                    )
                    item.pendingQty -=
                        Number(released || 0) + Number(rejected || 0)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async registerQuality() {
            try {
                this.loading = true
                const itemsToUpdate = this.items.filter(
                    i =>
                        i.releaseQty > 0 ||
                        i.reprocessQty > 0 ||
                        i.rejectQty > 0
                )

                if (itemsToUpdate.length > 0) {
                    itemsToUpdate.forEach(item => {
                        ;['releaseQty', 'reprocessQty', 'rejectQty'].forEach(
                            key => {
                                if (item[key]) {
                                    item[key] = Number(item[key])
                                }
                            }
                        )
                    })
                    await API.markBatchQuality({
                        items: itemsToUpdate,
                        workOrderId: this.workOrder.id,
                    })
                    this.$emit('closeDialog')
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        openConfirmDialog() {
            this.confirmDialog = true
        },

        closeConfirmDialog() {
            this.confirmDialog = false
        },

        close() {
            this.$emit('closeDialog')
        },

        disableButton() {
            return !this.items.find(
                item =>
                    item.releaseQty > 0 ||
                    item.reprocessQty > 0 ||
                    item.rejectQty > 0
            )
        },

        onResize() {
            this.height = window.innerHeight - 260
        },

        filterAvailable(item) {
            return item.pendingQty && item.pendingQty > 0
        },

        filterForPartNumber(item) {
            return (
                item.partNumber &&
                item.partNumber
                    .toLowerCase()
                    .includes(this.partNumberToFilter.toLowerCase())
            )
        },
        maxValue(item) {
            const total =
                Number(item.releaseQty || 0) +
                Number(item.reprocessQty || 0) +
                Number(item.rejectQty || 0)

            if (total > item.pendingQty) {
                return 'The value exceeds existences'
            } else {
                return true
            }
        },
    },
}
</script>

<style></style>
